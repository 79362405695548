.career-technician-img {
    height:100%;
    width:100%;
}
.styled-ordered-list li{
    list-style: decimal;
    margin: 20px;
}

.footer-links-light a {
    color:#666;
}
.footer-links-light a:is(:focus, :hover){
    list-style:disc;
    color:#29abe2;
}
.footer-links-dark a {
    color:#fff;
}
.footer-links-dark a:is(:focus, :hover){
    color:#29abe2;
}



.legal-doc-styling {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
}

.legal-doc-styling p {
    margin-bottom: 20px;
}
.legal-doc-styling ul {
    margin: 0 0 20px 20px;
}
.legal-doc-stylingli {
    margin-bottom: 10px;
}
.legal-doc-styling .section-title {
    font-weight: bold;
    margin-top: 20px;
}

/* nav item */
.nav-item {
    position: relative;
    display: inline-block;
}

.nav-item .nav-link {
    padding:5px;
    border: 2px solid #29abe2;
    border-radius: 5px;
    /* background-color: rgba(0, 0, 255, 0.1);  */
}