.notification-bar {
    background-color: transparent;
    position: fixed;
    top: 20%;
    right: -300px;
    width: 250px;
    height: 30%;
    transition: right 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 1000;
    overflow-y: auto;
}

.notification-bar.visible {
    font-size: 10px;
    width: auto;
    height: auto;
    max-height: 30%;
    right: 10px;
    background-color: transparent;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.badge {
    position: absolute;
    top: 25%;  /* Align the top edge of the badge with the center of the icon */
    right: 0;  /* Align the right edge of the badge with the right edge of the icon */
    transform: translate(50%, -50%); /* Adjust horizontally and vertically relative to the icon */
    border-radius: 50%;
    background-color: #de0c0c;
    color: white;
    padding: 0.25em;  /* Adjust padding to reduce the size */
    font-size: 0.7em;  /* Adjust font size to scale the number inside the badge */
    min-width: 1em;  /* Adjust minimum width for smaller badge */
    height: 1.2em;
    width: 1.2em;/* Adjust height for smaller badge */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.content {
    font-size: 14px !important
}
.card-bar {
    height: 70px !important;
    padding: 10px !important;
    align-content: center;
}

.card-content {
    margin-top: 0 !important;
    font-size: 15px  !important;
}

.button {
    margin-top: -10px  !important;
    font-size: 0.6rem !important;
    width: auto !important;
    height: 25px  !important;
}

.time {
    font-size: 14px !important;
}

.read-all-container {
    display: flex;
    justify-content: right;
}

.read-all {
    font-size: 15px !important;
    horiz-align: center !important;
    width: auto;
    height: 25px;
}

.empty {
    font-size: 20px;
    font-weight: normal;
    background-color: whitesmoke;
    align-items: center;
    text-align: center;
    padding: 20px 10px;
    width: 250px;
}
